import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'
import { Link } from 'gatsby'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const OnlineExclusiveProducts: React.FC<Props> = ({
  data,
  location,
}: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage: 'url(../../img/online-exclusive-product.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>Online Exclusive Products</h1>
          <p>
            On Aug 16th the first Online Exclusive Longines watches campaign
            will start on our E-Commerce platforms.
          </p>
          <p>
            300 pieces of new Longines DolceVita references fitted with four
            online exclusive alligator strap colors:
          </p>

          <div className="row">
            <div className="col-md-6 mb-4">
              <figure>
                <LightboxWrapper>
                  <a href={'../../img/LONGINES_DOLCEVITA-CAPRI_029.jpg'}>
                    <img
                      src={'../../img/LONGINES_DOLCEVITA-CAPRI_029.jpg'}
                      className="img-fluid thumbnail mb-1"
                    />
                  </a>
                </LightboxWrapper>
                <figcaption>
                  <strong>Lila</strong>
                  <br />
                  - L5.255.4.11.0
                  <br />- L5.512.4.11.0
                </figcaption>
              </figure>
            </div>
            <div className="col-md-6 mb-4">
              <figure>
                <LightboxWrapper>
                  <a href={'../../img/LONGINES_DOLCEVITA-CAPRI_059.jpg'}>
                    <img
                      src={'../../img/LONGINES_DOLCEVITA-CAPRI_059.jpg'}
                      className="img-fluid thumbnail mb-1"
                    />
                  </a>
                </LightboxWrapper>
                <figcaption>
                  <strong>Green</strong>
                  <br />
                  - L5.255.4.11.4
                  <br />- L5.512.4.11.4
                </figcaption>
              </figure>
            </div>
            <div className="col-md-6 mb-4">
              <figure>
                <LightboxWrapper>
                  <a href={'../../img/LONGINES_DOLCEVITA-CAPRI_049.jpg'}>
                    <img
                      src={'../../img/LONGINES_DOLCEVITA-CAPRI_049.jpg'}
                      className="img-fluid thumbnail mb-1"
                    />
                  </a>
                </LightboxWrapper>
                <figcaption>
                  <strong>Yellow</strong>
                  <br />
                  - L5.255.4.11.2
                  <br />- L5.512.4.11.2
                </figcaption>
              </figure>
            </div>
            <div className="col-md-6 mb-4">
              <figure>
                <LightboxWrapper>
                  <a href={'../../img/LONGINES_DOLCEVITA-CAPRI_039.jpg'}>
                    <img
                      src={'../../img/LONGINES_DOLCEVITA-CAPRI_039.jpg'}
                      className="img-fluid thumbnail mb-1"
                    />
                  </a>
                </LightboxWrapper>
                <figcaption>
                  <strong>Orange</strong>
                  <br />
                  - L5.255.4.11.3
                  <br />- L5.512.4.11.3
                </figcaption>
              </figure>
            </div>
          </div>
          <p>
            The watch is available in two sizes and is fitted with the Longines
            interchangeable system:
          </p>
          <ul>
            <li>L5.255 - 20.80 x 32 mm </li>
            <li>L5.512 - 23.30 x 37 mm</li>
          </ul>
          <p>
            This campaign will be pushed by social media organic posts and ads
            with powerful pictures and video taken from a photoshooting realized
            during July in Capri, Italy under the supervision of our colleagues
            from Longines Italy. Awesome work guys ! On the website, a home
            slide will be provided and will redirect to the Longines DolceVita
            collection page, where the new colors will be immediately visible.
            In addition, a tag similar to the bestseller and novelty one will be
            added with the mention “Online Exclusive”, so that our customers can
            easily identify the uniqueness of these products.
          </p>

          <p>
            <Link
              className="btn btn-primary"
              to="https://itsazrmgrswatchgroup-my.sharepoint.com/:f:/g/personal/lon-eraval_swatchgroup_net/Ehw5EQ6S9olPg4EQ59Z9sT0BjF1ZqY6HzYfWDkal0RHB5w?e=cgH861"
              target="_blank"
            >
              See Full Shooting here
            </Link>
          </p>
          <p>
            <Link
              className="btn btn-primary"
              to="https://itsazrmgrswatchgroup-my.sharepoint.com/personal/lon-eraval_swatchgroup_net/_layouts/15/onedrive.aspx?originalPath=aHR0cHM6Ly9pdHNhenJtZ3Jzd2F0Y2hncm91cC1teS5zaGFyZXBvaW50LmNvbS86ZjovZy9wZXJzb25hbC9sb24tZXJhdmFsX3N3YXRjaGdyb3VwX25ldC9FaHc1RVE2UzlvbFBnNEVRNTlaOXNUMEJqRjFacVk2SHpZZldEa2FsMFJIQjV3P3J0aW1lPWRhVWN0Q2RZMlVn&id=%2Fpersonal%2Flon%2Deraval%5Fswatchgroup%5Fnet%2FDocuments%2FDolceVita%202021%20Straps%2FPOST%5FDolceVita%5FEcomm%5Fexclu%5F%2Bcaptions%2Epdf&parent=%2Fpersonal%2Flon%2Deraval%5Fswatchgroup%5Fnet%2FDocuments%2FDolceVita%202021%20Straps"
              target="_blank"
            >
              Social Post Planning
            </Link>
          </p>
          <p>
            On Aug 16th, all these market will propose the new colors with at
            least one size available in stock and purchasable online.
          </p>
          <ul>
            <li>Australia</li>
            <li>Austria</li>
            <li>Belgium</li>
            <li>Canada</li>
            <li>France</li>
            <li>Germany</li>
            <li>Italy</li>
            <li>Japan</li>
            <li>Malaysia</li>
            <li>Netherlands</li>
            <li>Poland</li>
            <li>South Korea</li>
            <li>Spain</li>
            <li>Switzerland</li>
            <li>Russia</li>
            <li>UK</li>
            <li>USA</li>
          </ul>
          <p>
            We wish all markets a great success with this new campain. May it be
            the first of a long series of online exclusive Longines products !
          </p>
          <div className="spacer"></div>
          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/nils-lehmann.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:nils.lehmann@longines.com">
                    nils.lehmann@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default OnlineExclusiveProducts

export const pageQuery = graphql`
  query OnlineExclusiveProducts_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
